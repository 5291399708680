import "../../scss/landing/landing-entrypoint.css";

import "../common/bs-init.js";
import "./Animated-numbers-section-script.js";
import "./bold-and-bright.js";
import "./Image-Comparison-theme.js";
import "./Simple-Slider.js";
import "./theme.js";
import "./utilities.js";
import "../common/show-password-toggle.js"
import "./oAuth2.js"

if (document.getElementsByClassName("verification-page").length > 0) {
    import(/* webpackChunkName "verification" */ './verification.js')
        .then((verificationModule) => {
            verificationModule.init();
        }).catch(error => 'An error occurred while loading the verification page');
} else if (document.getElementsByClassName("login-page").length > 0) {
    import(/* webpackChunkName "login" */ './login.js')
        .then((loginModule) => {
            loginModule.init();
        }).catch(error => 'An error occurred while loading the login page');
} else if (document.getElementsByClassName("signup-page").length > 0) {
    import(/* webpackChunkName "signup" */ './signup.js')
        .then((signupModule) => {
            signupModule.init();
        }).catch(error => 'An error occurred while loading the signup page');
} else if (document.getElementsByClassName("change-password-page").length > 0) {
    import(/* webpackChunkName "changePassword" */ './changePassword.js')
        .then((changePasswordModule) => {
            changePasswordModule.init();
        }).catch(error => 'An error occurred while loading the change password data');
} else if (document.getElementsByClassName("reset-password-page").length > 0) {
    import(/* webpackChunkName "resetPassword" */ './passwordForget.js')
        .then((resetPasswordModule) => {
            resetPasswordModule.init();
        }).catch(error => 'An error occurred while loading the reset password page');
} else if (document.getElementsByClassName("index-page").length > 0) {
    import(/* webpackChunkName "index" */ './promoCode.js')
        .then((indexModule) => {
            indexModule.init();
        }).catch(error => 'An error occurred while loading the index page');
}

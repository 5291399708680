import {JWT_EXPIRATION} from "./constants";

export function jwtNotExpired() {
    let jwtExpiration = localStorage.getItem(JWT_EXPIRATION);

    return jwtExpiration && calculateTTL(jwtExpiration) > 0;
}

export function redirectToLogin() {
    Swal.fire({
        title: "Session ended!",
        html: "You will be redirected to the login page to authenticate again.",
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading();
        }
    }).then((result) => {
        localStorage.removeItem(JWT_EXPIRATION);
        window.location.replace('/login.html');
    });
}

export function showMessageForCustomerLocked() {
    Swal.fire({
        icon: 'error',
        title: 'Locked',
        text: 'Your account is locked. Please contact support.',
    }).then((result) => {
        if (result.isConfirmed) {
            localStorage.removeItem(JWT_EXPIRATION);
            window.location.replace('/index.html#contactSection');
        }
    });
}

function calculateTTL(jwtExpiration) {
    const expirationDate = new Date(jwtExpiration);
    const now = new Date();
    const ttl = expirationDate - new Date(now.toUTCString());

    if (ttl < 0) {
        return 0;
    }

    return ttl;
}

import {BASE_API, JWT_EXPIRATION} from "./constants.js";
import {redirectToLogin, showMessageForCustomerLocked} from "./common";

export function executePostRequest(url, data, successCallback, errorCallback) {
    return new Promise((resolve, reject) => {
        $.ajax({
            type: "POST",
            url: BASE_API + url,
            data: data,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (response) {
                if (successCallback) {
                    successCallback(response);
                }
                resolve(response);
            },
            error: function (jqXHR, status, error) {
                if (jqXHR.status === 401) {
                    // Redirect to the login page and replace the current page in history
                    localStorage.removeItem(JWT_EXPIRATION);
                    window.location.replace('/login.html');
                } else if (jqXHR.status === 403 && jqXHR.responseJSON.code === "CUSTOMER_LOCKED") {
                    showMessageForCustomerLocked();
                } else {
                    errorCallback(jqXHR, status, error);
                }
                reject(jqXHR.responseText);
            }
        });
    })
        ;
}

export function executePostFormDataRequest(url, data, successCallback, errorCallback) {
    return new Promise((resolve, reject) => {
        $.ajax({
            type: "POST",
            url: BASE_API + url,
            data: data,
            contentType: false,
            processData: false,
            success: function (response) {
                if (successCallback) {
                    successCallback(response);
                }
                resolve(response);
            },
            error: function (jqXHR, status, error) {
                if (jqXHR.status === 401) {
                    // Redirect to the login page and replace the current page in history
                    localStorage.removeItem(JWT_EXPIRATION);
                    window.location.replace('/login.html');
                } else if (jqXHR.status === 403 && jqXHR.responseJSON.code === "CUSTOMER_LOCKED") {
                    showMessageForCustomerLocked();
                } else {
                    errorCallback(jqXHR, status, error);
                }
                reject(jqXHR.responseText);
            }
        });
    })
        ;
}

export function executeGetRequest(url, queryParams, successCallback, errorCallback) {
    return new Promise((resolve, reject) => {
        $.ajax({
            type: "GET",
            url: BASE_API + url,
            data: queryParams,
            contentType: "application/json; charset=utf-8",
            success: function (response) {
                if (successCallback) {
                    successCallback(response);
                }
                resolve(response);
            },
            error: function (jqXHR, status, error) {
                if (jqXHR.status === 401) {
                    // Redirect to the login page and replace the current page in history
                    localStorage.removeItem(JWT_EXPIRATION);
                    window.location.replace('/login.html');
                } else if (jqXHR.status === 403 && jqXHR.responseJSON.code === "CUSTOMER_LOCKED") {
                    showMessageForCustomerLocked();
                } else {
                    errorCallback(jqXHR, status, error);
                }
                reject(jqXHR.responseText);
            }
        });
    });
}

export function executeDeleteRequest(url, successCallback, errorCallback) {
    return new Promise((resolve, reject) => {
        $.ajax({
            type: "DELETE",
            url: BASE_API + url,
            contentType: "application/json; charset=utf-8",
            success: function (response) {
                if (successCallback) {
                    successCallback(response);
                }
                resolve(response);
            },
            error: function (jqXHR, status, error) {
                if (jqXHR.status === 401) {
                    // Redirect to the login page and replace the current page in history
                    redirectToLogin();
                } else if (jqXHR.status === 403 && jqXHR.responseJSON.code === "CUSTOMER_LOCKED") {
                    showMessageForCustomerLocked();
                } else {
                    errorCallback(jqXHR, status, error);
                }
                reject(jqXHR.responseText);
            }
        });
    })
        ;
}

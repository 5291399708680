import {executePostRequest} from "../common/httpclient";
import {JWT_EXPIRATION} from "../common/constants";

window.handleCredentialResponse = (response) => {
    let newsletterGoogleCheckState = localStorage.getItem("newsletterGoogleCheckState");
    var successCallback = function (data) {
        $(".spinner").attr("hidden", true);
        localStorage.setItem("termsAccepted", data.termsAccepted.toString());
        localStorage.setItem(JWT_EXPIRATION, data.expiresAt.toString())
        window.location.replace("/image-processing.html");
    }

    var errorCallback = function (xhr, status, error) {
        $(".spinner").attr("hidden", true);
        if (xhr.responseJSON && errorCodeToErrorMessage.has(xhr.responseJSON.code)) {
            errorCodeToErrorMessage.get(xhr.responseJSON.code)();
        } else if (xhr.responseJSON.code === "CUSTOMER_LOCKED") {
            Swal.fire({
                icon: 'error',
                title: 'Locked',
                text: 'Your account is locked. Please contact support.',
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.replace('/index.html#contactSection');
                }
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'We have encountered an error',
                text: 'Please try again later',
            });
        }
    }

    var payload = {

        credential: response.credential,
        newsletterAccepted: newsletterGoogleCheckState
    };

    var data = JSON.stringify(payload);
    executePostRequest("/customer/oauth2/login", data, successCallback, errorCallback)
}

